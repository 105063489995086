import React from "react"

import LandingBio from "../components/landing-bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PublicationsPage = () => (
  <Layout>
    <SEO title="Publications" keywords={[`gatsby`, `application`, `react`]} />
    <div>
      <h2> Journal Articles </h2>
    <div>
      <h4><a href = "https://www.cambridge.org/core/journals/legal-information-management/article/abs/mission-possible-free-access-to-case-law-and-the-national-archives/EAAE92F8EA9EA68F253D5819014D9A53">Mission possible! Free access to case law and the National Archives
</a></h4>
      <p>Legal Information Management. 2023.</p>
    </div>
  <div>
      <h4><a href = "https://www.globelawandbusiness.com/journals/Modern-Lawyer-Journal">Supporting the next wave of innovation in law – how education plays a part
in making a change from the bottom up
</a></h4>
      <p>Modern Lawyer Journal. 2022.</p>
    </div>
  </div>
  <div>
      <h2> Conference Papers </h2>
      <div>
        <h4><a href = "http://ceur-ws.org/Vol-3257/paper6.pdf">Finding Case Law: Leveraging Machine Learning Research to Improve Public Access to UK Judgments</a></h4>
        <p>Joint Proceedings of ISWC2022 Workshops: the International Workshop on Artificial Intelligence Technologies for Legal
Documents (AI4LEGAL) and the International Workshop on Knowledge Graph Summarization (KGSum) (2022). 2022.</p>
      </div>
      <div>
        <h4><a href = "https://ebooks.iospress.nl/doi/10.3233/FAIA200875">Summarisation with Majority Opinion</a></h4>
        <p>Frontiers in Artificial Intelligence and Applications. Volume 334: Legal Knowledge and Information Systems. 2020.</p>
      </div>
    </div>
    <div>
      <h2> Blogs </h2>
      <div>
        <h4><a href = "https://www.legaltechnologist.co.uk/TheLegalTechnologistJan21.pdf">Access to Justice + Legal Tech = openTenancy</a></h4>
        <p>The Legal Technologist. 2021.</p>
      </div>
      <div>
        <h4><a href = "https://academy.avvoka.com/2021/04/27/implementation-seamless-workflows-with-integrations/">Seamless Workflows with Integrations</a></h4>
        <p>The Avvoka Angle. 2021.</p>
      </div>
      <div>
        <h4><a href = "https://lawstudent.blogs.bristol.ac.uk/2020/10/26/combining-my-legal-and-technical-skills-navigating-a-less-traditional-career-path-into-legaltech-and-academia-by-law-alumna-amy-conroy/">“Combining my legal and technical skills” – navigating a less traditional career path into LegalTech and academia</a></h4>
        <p>University of Bristol Law School. 2020.</p>
      </div>
      <div>
        <h4><a href = "https://engineering.blogs.bristol.ac.uk/legal-tech-law-and-computer-science/">Legal Tech: Law and Computer Science in partnership</a></h4>
        <p>University of Bristol Engineering. 2020.</p>
      </div>
    </div>

  </Layout>
)

export default PublicationsPage
